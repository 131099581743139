import p4Img from "../assets/p4.png";

export const products = [
  {
    id: "p4",
    name: "BCA & Adeziv",
    img: p4Img,
  },
];

export const dimensions = {
  BCA: ["650 x 100 x 250 x 120", "650 x 125 x 250 x 96", "650 x 150 x 250 x 80", "650 x 200 x 250 x 56", "650 x 250 x 250 x 48", "650 x 300 x 250 x 40", "650 x 375 x 250 x 32"],
  CARAMIDA: ["230 x 114 x 64", "240 x 115 x 88", "240 x 115 x 63", "250 x 300 x 238", "250 x 300 x 249", "250 x 120 x 65", "290 x 110 x 217", "290 x 140 x 88", "290 x 240 x 128", "290 x 240 x 238", "364 x 200 x 238", "365 x 180 x 138", "365 x 200 x 188", "375 x 145 x 217", "375 x 250 x 238", "375 x 250 x 249", "500 x 200 x 238"],
  BOLTAR: ["400 x 125 x 195", "500 x 300 x 195", "500 x 200 x 195", "500 x 200 x 250", "500 x 120 x 238"],
};
