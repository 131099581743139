import { h } from "preact";
import { useState } from "preact/hooks";
import style from "./App.css";
// import calcImg from "../assets/calculator.png";
import handClickImg from "../assets/hand_click.svg";
import { products, dimensions } from "./data";

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries()) || {};
const url = params.url || null;

const App = () => {
  const [product] = useState("p4");
  const [join, setJoin] = useState();
  const [dimType, setDimType] = useState("BCA");
  const [dimTarget, setDimTarget] = useState("");
  const [dim, setDim] = useState(null);
  const [qtyMc, setQtyMc] = useState("");
  const [qtyMp, setQtyMp] = useState("");
  const [qtyCm, setQtyCm] = useState("");
  const [showCalculate, setShowCalculate] = useState(false);

  const handleChangeJoin = (type) => () => {
    setJoin(type);
    if (product === "p2") {
      setDimType("BCA");
    }
    setShowCalculate(false);
  };

  const handleDimType = (type) => () => {
    setDimType(type);
    setDimTarget("");
    setDim(null);
    setShowCalculate(false);
  };

  const handleDimChange = (e) => {
    const dim = e.target.value;
    if (dim !== "other") {
      const [x, y, z, b] = dim.split("x").map((d) => parseInt(d.trim(), 10));
      setDim({ x, y, z, b });
    } else {
      setDim(null);
    }
    setDimTarget(e.target.value);
    setShowCalculate(false);
    setQtyMp("");
    setQtyMc("");
    scrollBottom();
  };

  const handleDimValuesChange = (type) => (e) => {
    const { value } = e.target;
    setDim({ ...dim, [type]: value ? parseFloat(value) : "" });
    setShowCalculate(false);
    setQtyMp("");
    setQtyMc("");
  };

  const handleDimSet = () => {
    scrollBottom();
  };

  const handleChangeQty = (type) => (e) => {
    const { value } = e.target;
    if (type === "MC") {
      setQtyMc(parseFloat(value));
      setQtyMp("");
    }
    if (type === "MP") {
      setQtyMp(parseFloat(value));
      setQtyMc("");
    }
    if (type === "CM") {
      setQtyCm(parseFloat(value));
    }
  };

  const handleCalculate = () => {
    if (!qtyMc && !qtyMp && !qtyCm) return;

    let results = { kg: "N/A", bags: "N/A" };
    if (product === "p1" || product === "p2" || product === "p4") {
      if (!dim) return;
      const { x, y, z, b } = dim;
      let qtyType = "mc";
      let qty = qtyMc;
      if (qtyMp) {
        qtyType = "mp";
        qty = qtyMp;
      }
      results = calculateTotal({ p: product, qty, join, qtyType, x, y, z, b });
    } else {
      results = calculateTotal({ p: product, qty: qtyCm });
    }
    setShowCalculate(results);
    scrollBottom();
  };

  const renderProducts = () => (
    <div class={style.animated}>
      <div class={style.products_container}>
        {products.map((p) => (
          <div key={p.id}>
            <div class={`${style.product}`}>
              <img src={p.img} style={{ widht: "100%" }} />
            </div>
          </div>
        ))}
      </div>
      <div class={style.title} style={{ marginTop: 10 }}>
        Calculeaza necesarul de materiale pentru sistemul de zidarie BCA Performo si TectorPlast 130 BCA
      </div>
    </div>
  );

  const renderJoins = () => (
    <div class={`${style.joins_wrap} ${style.animated}`}>
      <div class={style.title}>
        <div class="color-white">Selecteaza tip îmbinare</div>
      </div>
      <div class={style.joins_container}>
        <div class={`${style.button} ${join === 2 && style.button_active}`} onClick={handleChangeJoin(2)}>
          Cu Nut si Feder
        </div>
        <div class={`${style.button} ${join === 1 && style.button_active}`} onClick={handleChangeJoin(1)}>
          Fara Nut si Feder
        </div>
      </div>
    </div>
  );

  const renderDimensions = () => (
    <div class={style.animated}>
      <div class={style.title}>
        <div class="color-blue">
          1. Selecteaza BCA <span style={{ fontSize: 17 }}>(lungime x grosime x inaltime)</span>
        </div>
      </div>
      {product === "p1" && (
        <div class={style.dim_container}>
          <div class={`${style.button} ${dimType === "CARAMIDA" && style.button_active}`} onClick={handleDimType("CARAMIDA")}>
            Pentru CaRaMIDa
          </div>
          <div class={`${style.button} ${dimType === "BOLTAR" && style.button_active}`} onClick={handleDimType("BOLTAR")}>
            Pentru BOLtAR
          </div>
          <div class={`${style.button} ${dimType === "BCA" && style.button_active}`} onClick={handleDimType("BCA")}>
            Pentru BCA
          </div>
        </div>
      )}
      {dimType && (
        <>
          <select aria-label="Dimensiuni" class={style.dd} value={dimTarget} onChange={handleDimChange}>
            <option value="" selected>
              Selecteaza dimensiune
            </option>
            {dimensions[dimType].map((d) => (
              <option value={d} key={d}>
                {d.split("x").slice(0, -1).join(" x ")}
              </option>
            ))}
          </select>
          mm
        </>
      )}
      {dimTarget === "other" && (
        <div class={style.qnty_container}>
          <input name="x" placeholder="Lungime" type="number" min="0" value={dim?.x} onChange={handleDimValuesChange("x")} />
          <span>x</span>
          <input name="y" placeholder="Latime" type="number" min="0" value={dim?.y} onChange={handleDimValuesChange("y")} />
          <span>x</span>
          <input name="z" placeholder="Inaltime" type="number" min="0" value={dim?.z} onChange={handleDimValuesChange("z")} />
          <div class={`${style.button} ${style.button_active}`} style={{ display: "inline-block" }} onClick={handleDimSet}>
            Seteaza
          </div>
        </div>
      )}
      {dimType && (
        <div class={style.disclaimer}>
          {product === "p1" && <div>* Grosimea rostului este de 1.5 cm</div>}
          {product === "p2" && <div>* Grosimea rostului este de 1.5 cm</div>}
          {product === "p4" && <div>* Grosimea rostului este de 1.5 mm</div>}
          {!!dim && dim.x === 650 && dim.y === 200 && dim.z === 250 && <div>* Paletul contine si 8 blocuri de BCA cu dimensiunile: 650x100x250mm</div>}
        </div>
      )}
    </div>
  );

  const renderQnty = () => (
    <div class={`${style.qnty_wrap} ${style.animated}`}>
      <div class={style.title}>
        <div>2. Introdu suprafata de zidit</div>
      </div>
      <div class={style.qnty_container}>
        <input
          name="qtyMc"
          // placeholder="m³"
          type="number"
          min="0"
          value={qtyMc}
          onChange={handleChangeQty("MC")}
        />
        <label>m³</label>
        <span>sau</span>
        <input
          name="qtyMp"
          // placeholder="m²"
          type="number"
          min="0"
          value={qtyMp}
          onChange={handleChangeQty("MP")}
        />
        <label>m²</label>
        <div class={`${style.button} ${style.button_active}`} style={{ display: "inline-block" }} onClick={handleCalculate}>
          Calculeaza
        </div>
      </div>
    </div>
  );

  const renderQntyP3 = () => (
    <div class={`${style.qnty_wrap} ${style.animated}`}>
      <div class={style.title}>
        <div class="color-white">Suprafata de tencuit</div>
      </div>
      <div class={style.qnty_container}>
        <input
          id="qtyMp"
          name="qtyMp"
          // placeholder="m²"
          type="number"
          min="0"
          value={qtyCm}
          onChange={handleChangeQty("CM")}
        />
        <label>m²</label>
        <div class={`${style.button} ${style.button_active}`} style={{ display: "inline-block" }} onClick={handleCalculate}>
          Calculeaza
        </div>
      </div>
      <div class={style.disclaimer}>
        <div>* Grosimea stratului este de 1.5 cm</div>
      </div>
    </div>
  );

  const renderCalculate = () => (
    <>
      <div class={`${style.calc_container} ${style.animated}`}>
        <div class={style.title}>3. Afla necesarul de materiale</div>
        <table class={style.calc_table}>
          <tr>
            <td class={style.calc_table_t}>Nr. BLOCURI Performo BCA</td>
            <td class={style.calc_table_v}>
              <b>{Math.round(showCalculate.blocks)}</b>
            </td>
          </tr>
          <tr>
            <td class={style.calc_table_t}>Nr. PALETI Performo BCA</td>
            <td class={style.calc_table_v}>
              <b>{Math.round(showCalculate.kg)}</b>
            </td>
          </tr>
          <tr>
            <td class={style.calc_table_t}>Nr. SACI adeziv TectorPlast (25kg)</td>
            <td class={style.calc_table_v}>
              <b>{Math.round(showCalculate.bags)}</b>
            </td>
          </tr>
        </table>
      </div>
      <div class={`${style.qnty_wrap} ${style.animated}`} style={{ marginBottom: 20 }}>
        <div class={style.title}>Construieste cu arta si precizie folosind noul sistem de zidarie termoizolanta Holcim PERFORMO BCA!</div>
        <br />
        <div style={{ textAlign: "center", marginBottom: 20 }}>
          <img src={handClickImg} width="35" />
          <a href={url ? url : "https://www.holcim.ro/ro/bca"} target="_parent" rel="noreferrer" class={style.offer_link}>
            Inapoi la pagina BCA
          </a>
        </div>
      </div>
    </>
  );

  return (
    <div class={style.wrapper}>
      <div class={style.container}>
        <div>{renderProducts()}</div>
        {product !== "p3" && product !== "p4" && (
          <>
            <div>{product && renderJoins()}</div>
            <div>{join && renderDimensions()}</div>
            <div>{dim && dim.x && dim.y && dim.z && renderQnty()}</div>
          </>
        )}
        {product === "p4" && (
          <>
            {/* <div>{renderMsg()}</div> */}
            <div>{renderDimensions()}</div>
            <div>{dim && !!dim?.x && dim?.y && dim?.z && renderQnty()}</div>
          </>
        )}
        {product === "p3" && <div>{renderQntyP3()}</div>}
        <div>{showCalculate && renderCalculate()}</div>
      </div>
    </div>
  );
};

function scrollBottom() {
  setTimeout(() => window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" }), 1);
}

function calculateTotal({ p, qty, join, qtyType, x, y, z, b }) {
  // eslint-disable-next-line prefer-rest-params
  console.log("ARGS", arguments[0]);
  const kg = qtyType === "mp" ? ((x * y * 1.5 + y * z * 1.5) / 1000000) * 2 * (1000000 / (x * z)) * qty : ((x * y * 1.5 + y * z * 1.5) / 1000000) * 2 * (1000000 / (x * z)) * (1000 / y) * qty;
  const blocks = qtyType === "mp" ? qty / ((x * z) / 1000000) : qty / ((x / 1000) * (y / 1000) * (z / 1000));
  const bags = kg / 25;
  const paleti = blocks / b;

  return {
    kg: paleti,
    bags,
    blocks,
  };
}

export default App;
